/// <reference path="lib/protoaculous/protoaculous.1.9.0-1.7.2.0.js" />

Ajax.Request.addMethods({
    initialize: function ($super, url, options) {
	$super(options);
	this.transport = Ajax.getTransport();
	// Note that the cssversion parameter is *not* added to fix a caching issue.
	// It is parsed by the Node class on the server to tell which version of
	// csc.css the client is using. If the version that is known on the server
	// is newer than the version used on the client, any controls returned by this
	// AJAX request do not rely on CSS to render their styling but just use inline
	// style attributes.
	// As a side effect, this breaks caching of static content (such as SVGs) when
	// the cssversion changes frequently (which is happens a lot on playa due to
	// the frequent restarts after deploys there.)
	// In short: This is an evil hack but a necessity at the moment. Workarounds,
	// like the logic used by FeedLoad (replace the css section of the html document
	// when necessary) are even worse.
	if (url.indexOf('?') == -1)
	    this.request(url + '?cssversion=' + cssversion);
	else
	    this.request(url + '&cssversion=' + cssversion);
    },
});

Prototype.Browser.IE6 = Prototype.Browser.IE && parseInt(navigator.userAgent.substring(navigator.userAgent.indexOf("MSIE") + 5)) == 6;
Prototype.Browser.IE7 = Prototype.Browser.IE && parseInt(navigator.userAgent.substring(navigator.userAgent.indexOf("MSIE") + 5)) == 7;

// For IE11
Prototype.Browser.Trident = navigator.userAgent.include('Trident');
Prototype.Browser.Gecko = !Prototype.Browser.Trident;


//c+p from 'http://distillations.2rye.com/2012/02/javascript-tricks-a-data-method-for-prototype/'
var PrototypeExtensions = {
    data: function (elem, key, val) {
	var DATA_REGEX = /data-(\S+)/;
	var ii = 0;
	var nattr = elem.attributes.length;
	if (key && val) {
	    elem.setAttribute('data-' + key, val);
	    return elem;
	}
	else {
	    for (; ii < nattr; ++ii) {
		var attr = elem.attributes[ii];
		if (attr && attr.name) {
		    var m = attr.name.match(DATA_REGEX);
		    if (m && m.length > 1) {
			var datakey = m[1];
			if (datakey === key) {
			    return attr.value;
			}
		    }
		}
	    }
	}
	return null;
    }
};

Element.addMethods(PrototypeExtensions);

// taken from https://github.com/kangax/protolicious/blob/5b56fdafcd7d7662c9d648534225039b2e78e371/event.simulate.js
/**
 * Event.simulate(@element, eventName[, options]) -> Element
 * 
 * - @element: element to fire event on
 * - eventName: name of event to fire (only MouseEvents and HTMLEvents interfaces are supported)
 * - options: optional object to fine-tune event properties - pointerX, pointerY, ctrlKey, etc.
 *
 *    $('foo').simulate('click'); // => fires "click" event on an element with id=foo
 *
 **/
(function () {
	var eventMatchers = {
		'HTMLEvents': /^(?:load|unload|abort|error|select|change|submit|reset|focus|blur|resize|scroll)$/,
		'MouseEvents': /^(?:click|mouse(?:down|up|over|move|out))$/
	};
	var defaultOptions = {
		pointerX: 0,
		pointerY: 0,
		button: 0,
		ctrlKey: false,
		altKey: false,
		shiftKey: false,
		metaKey: false,
		bubbles: true,
		cancelable: true
	};

	Event.simulate = function (element, eventName) {
		var options = Object.extend(defaultOptions, arguments[2] || {});
		var oEvent, eventType = null;

		element = $(element);

		for (var name in eventMatchers) {
			if (eventMatchers[name].test(eventName)) { eventType = name; break; }
		}

		if (!eventType)
			throw new SyntaxError('Only HTMLEvents and MouseEvents interfaces are supported');

		if (document.createEvent) {
			oEvent = document.createEvent(eventType);
			if (eventType == 'HTMLEvents') {
				oEvent.initEvent(eventName, options.bubbles, options.cancelable);
			}
			else {
				oEvent.initMouseEvent(eventName, options.bubbles, options.cancelable, document.defaultView,
					options.button, options.pointerX, options.pointerY, options.pointerX, options.pointerY,
					options.ctrlKey, options.altKey, options.shiftKey, options.metaKey, options.button, element);
			}
			element.dispatchEvent(oEvent);
		}
		else {
			options.clientX = options.pointerX;
			options.clientY = options.pointerY;
			oEvent = Object.extend(document.createEventObject(), options);
			element.fireEvent('on' + eventName, oEvent);
		}
		return element;
	};

	Element.addMethods({ simulate: Event.simulate });
})();
